import React from 'react';
import { Row, Col,Spin } from 'antd';
import { useNavigate } from "react-router-dom";

import { Button, Checkbox, Form, Input, Modal,Statistic,message} from 'antd';
import './index.less'
import ImgCode from  '../../component/ImgCode';
import {getCodeApi, registerApi} from '../../service/api'
import {getResponse, PRE_URL} from '../../common/config'
import MD5 from 'MD5';
const { Countdown } = Statistic
class Content extends React.PureComponent {
  state = {
    isVilid :false,
    showModal: false,
    loading: false,
    showCount: false,
    count: 60,
    phone:'',
    url: ''
  }
  componentDidMount () {
  

  }
  onFinish = async (values) => {
    this.setState({
      loading: true
    })
    const res = await registerApi({...values});
    if(getResponse(res)) {
      message.success('注册成功！')
      window.location.href= `${PRE_URL}/#/login`
     
    }
    this.setState({
      loading: false
    })
  };
  getMsgCode = () => {
    if(this.state.phone && this.state.phone.length === 11){
      if(!this.state.isVilid){
        this.setState({
          showModal: true,
        })
      } else {
        this.sendMsgCode()
      }
    }else {
      message.error('请输入手机号!')
    }
    
    
  }
  sendMsgCode = async() => {
    // 接口
    const phoneRegExg = /^1[0-9]{10}$/;
    const reg  = new RegExp(phoneRegExg);
    if(!this.state.phone || this.state.phone && !reg.test(this.state.phone)){
      message.warning('请输入手机号', [2])
      return;
   }
    const res = await getCodeApi({mobile:this.state.phone ,smsmode: '1'});
    if(getResponse(res)) {
      this.setState({
        showCount:true
      })
      this.runGetCaptchaCountDown()
    }

  }
  runGetCaptchaCountDown = () => {
    let count = this.state.count || 59;
    this.setState({ count });
    this.interval = setInterval(() => {
      count -= 1;
      this.setState({ count });
      if (count === 0) {
        this.setState({
          showCount:false
        })
        clearInterval(this.interval);
      }
    }, 1000);
  };
  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const {onFinish, getMsgCode, sendMsgCode} = this;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    return (
      <Spin spinning={this.state.loading}>
      <div className={'register_wrap'}>
                <h1 style={{marginBottom: '20px'}}>注册</h1>
      <Form
        {...formItemLayout}
        name="register"
        onFinish={onFinish}
        className="register-form"
        initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
        style={{ maxWidth: 600 }}
        scrollToFirstError
      >
   
      <Form.Item
        name="mobile"
        label="手机号"
        rules={[{ required: true, message: '  请输入手机号!' ,pattern:new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, "g")},]}
      >
        <Input maxLength={11}  style={{ width: '100%' }} onChange={(e) => {
          this.setState({
            phone: e.target.value
          })
        }} />
      </Form.Item>
      <Form.Item label="验证码" >
        <Row gutter={8}>
          <Col span={14}>
            <Form.Item
              name="smscode"
              noStyle
              rules={[{ required: true, message: '请输入验证码' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Button style={{width:'100%'}} onClick={getMsgCode}>{this.state.showCount ? this.state.count : '获取验证码'}</Button>
          </Col>
        </Row>
      </Form.Item>
      
      <Form.Item
        name="password"
        label="密码"
        rules={[
          {
            required: true,
            message: '请输入密码!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="确认密码"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: '请确认密码!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('密码错误!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="username"
        label="用户名"
        rules={[{ required: true, message: '请输入用户名!', whitespace: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="email"
        label="邮箱"
        rules={[
          {
            type: 'email',
            message: '请输入正确的邮箱!',
          },
          {
            required: true,
            message: '请输入邮箱!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="company"
        label="公司名称"
        rules={[

          {
            required: true,
            message: '请输入公司名称!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="address"
        label="联系地址"
        rules={[

          {
            required: true,
            message: '请输入联系地址!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="realName"
        label="称呼"
        rules={[

          {
            required: true,
            message: '请输入称呼!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button style={{width:'100%'}} type="primary" htmlType="submit">
          注册
        </Button>
      </Form.Item>
    </Form>
    <Modal
        // open={open}
        title="图形验证"
        // onOk={handleOk}
        onCancel={() => {
          this.setState({
            showModal: false
          })
        }}
        visible={this.state.showModal}
        footer={[]}
      >
       <ImgCode
          imageUrl={this.state.url}
          onReload={this.onReload}
          onMatch={() => {
            setTimeout(() => {
              this.setState({
                showModal: false,
                isVilid: true
              })
              sendMsgCode()
            }, 1000)
          }}
        /> 
      </Modal>
      </div>
      </Spin>
    );
  }
}

export default Content;
