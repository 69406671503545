import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import { getChildrenToRender } from './utils';
import { isImg } from './utils';
import {getTopBottom} from '../../service/api'
import {getResponse, BASIC_IMG} from '../../common/config';
import { Link } from 'react-router-dom';


class Footer extends React.Component {
  state = {
    list: []
  }
  static defaultProps = {
    className: 'footer1',
  };
  componentDidMount() {
    this.getData()
  }
  async getData ()  {
    const res = await getTopBottom({type: 'bottom'});
    if(getResponse(res)) {
      this.setState({
        list: res.result
      })
    }
  }
  getLiChildren = (data) =>
    data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item;
      return (
        <Col key={i.toString()} {...itemProps}  title={null} content={null}>
          <h2 {...title}>
            {typeof title.children === 'string' &&
            title.children.match(isImg) ? (
              <img src={title.children} width="100%" alt="img" />
            ) : (
              title.children
            )}
          </h2>
          <div {...childWrapper}>
            {childWrapper.children.map(getChildrenToRender)}
          </div>
        </Col>
      );
    });

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    const {list} = this.state;
    delete props.dataSource;
    delete props.isMobile;
    // const childrenToRender = this.getLiChildren(dataSource.block.children);
    return (
      <div {...props} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
            component={Row}
            justify="center" align="middle" style={{minHeight:'10vh', textAlign: 'center'}}
            {...dataSource.block}
          >
            {/* {childrenToRender} */}
            {
              list.map((item, index) => {
                return (
                  <Col key={`footer_item_${index}`} md={8} xs={3} className="block" style={{align: 'center'}}>
                    <img className="tr2" src={`${BASIC_IMG}${item.imgPath}`} style={{backgroundSize: 'cover', width: '50%'}} />	
                    <div style={{margin: '10px', fontSize: '22px'}}><Link to={`/contact`}><span><p>{item.title}</p></span></Link></div>
                  </Col>
                )
              })
            }
          </QueueAnim>
          <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            {...dataSource.copyrightWrapper}
          >
            <div {...dataSource.copyrightPage}>
              <div {...dataSource.copyright}>
                {dataSource.copyright.children}
              </div>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Footer;
