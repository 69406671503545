import React, {useState, useEffect} from 'react';
import Layout from '../../Layout';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import {getGoodsList} from '../../service/api'
import {getResponse} from '../../common/config'
import QueueAnim from 'rc-queue-anim';
// const data = Array.from({ length: 23 }).map((_, i) => ({
//   href: 'https://ant.design',
//   title: `ant design part ${i}`,
//   // avatar: `https://joesch.moe/api/v1/random?key=${i}`,
//   description:
//     'Ant Design, a design language for background applications, is refined by Ant UED Team.',
//   content:
//     'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
// }));


const Content = () => {
  const [records, setrecords] = useState([])

  useEffect(() => {
    getData({})
    return () => {
      
    }
  }, [])
  const  getData = async (params) => {
    // pageNo  pageSize type title
    const param = {
      pageNo: 1,
      pageSize: 10,
      type: 'contact',
      ...params,
    }
    const res = await getGoodsList(param);
    if(getResponse(res)) {
      const {contentResultVo: {content    }} = res.result;
      setrecords(content)
    }
  }
  return (
    <Layout>
     <div className="home-page-wrapper content0-wrapper">
        <div className="home-page content0">
          <div className="title-wrapper">
            <h1 name="title">联系我们</h1>
          </div>
          <OverPack playScale={0.3}>
            <QueueAnim key="u" type="bottom">
              <div dangerouslySetInnerHTML={{__html:records}}></div>
            </QueueAnim>
          </OverPack>          
          </div>
      </div>
    </Layout>
  )
};

export default Content;
