import React, {useState, useEffect} from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from '../Home/utils';
import Layout from '../../Layout';
import {getGoodsList} from '../../service/api'
import {getResponse} from '../../common/config'

const  Content =  ({dataSource}) => {
  const {
    childWrapper,
  } = dataSource;
  const [records, setrecords] = useState([])
  useEffect(() => {
    getData()
    return () => {
    }
  }, [])
  const  getData = async () => {
    // pageNo  pageSize type title
    const param = {
      pageNo: 1,
      pageSize: 20,
      type: 'onlineShop',
    }
    const res = await getGoodsList(param);
    if(getResponse(res)) {
      const {contentResultVo: {content, total      }} = res.result;
      setrecords(content)
    }
  }
  return(
    <Layout>
       <div className="home-page-wrapper content0-wrapper">
        <div className="home-page content0">
          <div className="title-wrapper">
            <h1 name="title">线上商城</h1>
          </div>
          <OverPack playScale={0.3}>
            <QueueAnim key="u" type="bottom">
              <div dangerouslySetInnerHTML={{__html:records}}></div>
            </QueueAnim>
          </OverPack>
          
        </div>
      </div>
      </Layout>
  )
}

export default Content;
