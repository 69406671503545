import React from 'react';
import TweenOne from 'rc-tween-one';
import { Menu, Space,  Avatar,Dropdown} from 'antd';
import { getChildrenToRender } from './utils';
import { UserOutlined } from '@ant-design/icons';
import {getTopBottom} from '../../service/api'
import {getResponse, BASIC_IMG, PRE_URL} from '../../common/config';
const { Item, SubMenu } = Menu;

class Header3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: undefined,
      selectedKeys: '',
      title: {},
    };
  }
  componentDidMount=() => {
    const { dataSource, } = this.props;
    const navData = dataSource.Menu.children;
    const item = navData.find(v=> v.children.href === `/${window.location.hash}`)
    this.setState({
      selectedKeys: item ? item.name : ''
    })
    this.getData();
  }
  async getData ()  {
    const res = await getTopBottom({type: 'title'});
    if(getResponse(res)) {
      this.setState({
        title: res.result[0]
      })
    }
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };


  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { phoneOpen } = this.state;
    const navData = dataSource.Menu.children;
    const user = localStorage.getItem('userInfo');
    const userData = user ? JSON.parse(user): {};
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      if (subItem) {
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            title={
              <div
                {...a}
                className={`header3-item-block ${a.className}`.trim()}
              >
                {a.children.map(getChildrenToRender)}
              </div>
            }
            popupClassName="header3-item-child"
          >
            {subItem.map(($item, ii) => {
              const { children: childItem } = $item;
              const child = childItem.href ? (
                <a {...childItem}   >
                  {childItem.children.map(getChildrenToRender)}
                </a>
              ) : (
                <div {...childItem} >
                  {childItem.children.map(getChildrenToRender)}
                </div>
              );
              return (
                <Item key={$item.name || ii.toString()} {...$item}>
                  {child}
                </Item>
              );
            })}
          </SubMenu>
        );
      }
      return (
        <Item key={item.name} {...itemProps}>
          <a {...a} className={`header3-item-block ${a.className}`.trim()}>
            {a.children.map(getChildrenToRender)}
          </a>
        </Item>
      );
    });
    const moment = phoneOpen === undefined ? 300 : null;
    const token = localStorage.getItem('token')
    const items = !token ? [
      {
        label: '去登录',
        key: '1',
      },
    ] :  [
      {
        label: '退出登录',
        key: '0',
      },
    ];
    const onClick= ({ key }) => {
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      window.location.href = `${PRE_URL}/#/login` 
    };
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
        >
          <Dropdown menu={{ items, onClick }} trigger={['click']}>

            <Space wrap size={16} className="header3-nav_user">
                <Avatar  style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                  <span>{userData && userData.username ? userData.username :'游客'}</span>
            </Space>
          </Dropdown>
          <TweenOne
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
            {...dataSource.logo}
            
          >
            {
              this.state.title.imgPath ? <img style={{width: 30, height:30}} src={`${BASIC_IMG}${this.state.title.imgPath}`} alt="img" /> : null
            }
            
             <span style={{marginLeft: 5, fontWeight: 'bold', fontSize: 18}}>{this.state.title.title}</span>
          </TweenOne>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne
            {...dataSource.Menu}
            animation={
              isMobile
                ? {
                    x: 0,
                    height: 0,
                    duration: 300,
                    onComplete: (e) => {
                      if (this.state.phoneOpen) {
                        e.target.style.height = 'auto';
                      }
                    },
                    ease: 'easeInOutQuad',
                  }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            <Menu
              mode={isMobile ? 'inline' : 'horizontal'}
              defaultSelectedKeys={['item0']}
              selectedKeys={[this.state.selectedKeys]}
              theme="light"
            >
              {navChildren}
            </Menu>
          </TweenOne>
          
        </div>
       
      </TweenOne>
    );
  }
}

export default Header3;
