import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { HashRouter as Router,Routes, Route, Link } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Content from './view/Home/Content0.jsx';
import Nav3 from './view/Home/Nav3';
import Online from './view/Online';
import Home from './view/Home';
import Footer from './view/Home/Footer1.jsx';
import Login from './view/Login'
import Register from './view/Register'
import Business from './view/Business'
import Contact from './view/Contact'
import CustomMade from './view/CustomMade'
import Notice from './view/Notice'
import NoticeDetail from './view/Notice/Detail'
import Product from './view/Product'
import ProductDetail from './view/Product/Detail'
import Exception404 from './view/Exception/404';
import {
  Nav30DataSource,
  Banner10DataSource,
  Content00DataSource,
  Content50DataSource,
  Content30DataSource,
  Feature50DataSource,
  Footer10DataSource,
} from './view/Home/data.source';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    return (
      <Router>
        <div>
  
          <Routes>
    
              {/* <Content dataSource={Nav00DataSource} isMobile={this.isMobile} /> */}
              <Route exact path="/" element={<Home />} />
              <Route exact path="/Online" element={<Online      id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}/>} />
              <Route exact path="/Content" element={<Content      id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}/>} />

<Route exact path="/Business" element={<Business      id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}/>} />
               <Route exact path="/Notice" element={<Notice      id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}/>} />
              <Route exact path="/NoticeDetail" element={<NoticeDetail     id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}/>} />
               <Route exact path="/Contact" element={<Contact      id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}/>} />
               <Route exact path="/CustomMade" element={<CustomMade      id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}/>} />
               <Route exact path="/Product" element={<Product      id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}/>} />
         <Route exact path="/ProductDetail" element={<ProductDetail     id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}/>} />
           <Route exact path="/Login" element={<Login/>} />
           <Route exact path="/Register" element={<Register/>} />
           <Route exact path="/404" element={<Exception404/>} />

           
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;
