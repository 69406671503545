import React from 'react';
import  { TweenOneGroup } from 'rc-tween-one';
import  { Element } from 'rc-banner-anim';
import 'rc-banner-anim/assets/index.css';
import {getTopBottom} from '../../service/api'
import {getResponse} from '../../common/config';
import './less/imgItem.less';
const { BgElement } = Element;
class ImgItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
  }
  componentDidMount() {
    this.getData()
  }
  async getData ()  {
    const res = await getTopBottom({type: 'top'});
    if(getResponse(res)) {
      this.setState({
        list: res.result
      })
    }
  }
  render() {
    const { ...props } = this.props;
    return (
      <div {...props} className="ImgItem">
        <TweenOneGroup
          key="bannerGroup"
          enter={{ opacity: 0, type: 'from' }}
          leave={{ opacity: 0 }}
          component=""
        >
          <div className="ImgItem-wrapper" key="wrapper">
          <BgElement key="bg" className='bg'>
          <div className="text-2 text-center" style={{backgroundColor: 'black', marginTop: '60px', height: '100%'}}>
							<h3>因为专注 所以专业</h3>
							<p className="no-margin">公司主要销售挂钟、闹钟、座钟等研发生产，提供OEM、批发、一件代发等，为企业公司提供一站式服务。</p>
						</div>
          </BgElement>

          </div>
        </TweenOneGroup>
        
      </div>
    );
  }
}

export default ImgItem;
