import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col, Card } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from '../Home/utils';
import Layout from '../../Layout';
import List from './component/listItem'
import {getGoodsList} from '../../service/api'
import {getResponse} from '../../common/config'
import Banner1 from '../Home/Banner1'
import {
  Nav30DataSource,
  Banner10DataSource,
  Content10DataSource,
  Content50DataSource,
  Content30DataSource,
  Feature50DataSource,
  Footer10DataSource,
} from '../Home/data.source';
const { Meta } = Card;
class Content extends React.PureComponent {

  render() {

    return (
      <Layout>
         <Banner1
            id="Banner1_0"
            key="Banner1_0"
            dataSource={Banner10DataSource}
          />
         <List />  
      </Layout>
    );
  }
}

export default Content;
