import React from 'react';
import { Card } from 'antd';
import { Button, Result } from 'antd';
import Layout from '../../Layout';
const { Meta } = Card;
class Content extends React.PureComponent {
  render() {
    return (
      <Layout>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      </Layout>
    );
  }
}

export default Content;
