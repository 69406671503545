import {message} from 'antd';
export const isDevelopment = () => process.env.NODE_ENV === 'development';
const pre = window.location.origin;
export const BASE_URL = isDevelopment() ? '/jeecg-boot' : pre + '/jeecg-boot';

export const PRE_URL = '';
// export const PRE_URL = '/build';
export const RESPONSE_CODE = {
  success: 0,
};

export const BASIC_CONFIG = {
  BASE_URL,
};
export const BASIC_IMG = '/jeecg-boot/sys/common/static/';

export const commonProject = 'iotc-wx'


export const getResponse = (res) => {
  if(res&&res.success) {
    return true;
  } else {
    if(res && res.message) message.error(res && res.message)
    return false;
  }
}