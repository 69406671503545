import React, { useEffect, useState } from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Link } from 'react-router-dom';
import { getGoodsList } from '../../service/api';
import { getResponse, BASIC_IMG } from '../../common/config';
import noImage from '../../assets/noImage.jpeg'
function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  const [news, setnews] = useState([])
  const [info, setinfo] = useState([])
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  useEffect(() => {
    getData();
    getData2();
    return () => {
    }
  }, [])
  const getData= async() => {
    const param = {
      pageNo: 1,
      pageSize: 4,
      type: 'announcement-news',
      listFlag: 1
    }
    const res = await getGoodsList(param);
    if(getResponse(res)) {
      const {listGoodsResultVo: {records, total      }} = res.result;
      setnews(records)
    }
  }
  const getData2= async() => {
    const param = {
      pageNo: 1,
      pageSize: 4,
      type: 'announcement-info',
      listFlag: 1
    }
    const res = await getGoodsList(param);
    if(getResponse(res)) {
      const {listGoodsResultVo: {records, total      }} = res.result;
      setinfo(records)
    }
  }

  return (
    <div {...tagProps} className='home-page-wrapper content12-wrapper'>
      <OverPack className='home-page content12' playScale={0.3} component={Row}>
        <QueueAnim
          key="text"
          type={'left'}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          className='content12-text'
          component={Col}
          componentProps={{
            md: 12,
            xs: 12,
          }}
        >
          <div className="title-3 clearfix">
            <Link to={"/Notice"}>
							<div className="left pull-left">
								<a  className="tr">
									公司新闻<span className="text-uppercase tr">News</span>
								</a>
							</div>
							<div className="right pull-right">
								<a className="tr">MORE +</a>
							</div>
              </Link>
						</div>

            
          <div className="pictrue-4">
            
            
              <Link className="box_a" to={`/NoticeDetail?id=${news[0]&& news[0].id}`}>
                <div className="imgbox">
                  {
                    <img className="tr2" src={news[0] && news[0].imgPath?`${BASIC_IMG}${news[0].imgPath}`: noImage} style={{backgroundSize: 'cover', width:'100%'}} />	
                  }
                
                </div>
                <div className="textbox">
                  <div className="biaoti">
                    <h5 className="no-margin overflow tr">{news[0]&& news[0].title}</h5>
                    <span className="text-right">{news[0] ?news[0].createTime.substring(0, 10):''}</span>
                  </div>
                  <p>{news[0]&&news[0].showDesc}</p>
                  <div className="more text-center tr">详情&gt;&gt;</div>
                </div>
                </Link>
            
          </div>
          <div className="text-4">
            <ul className="list-unstyled no-margin clearfix">
            
                {
                  news.map((v, index) => {
                    if(index !=0) {
                      return (
                        <li key={`home_news_${index}`} className="overflow li0">
                               <Link to={`/NoticeDetail?id=${v.id}`}>
                          <a  className="tr">{v.title}</a>
                          <span className="text-right">{v.createTime.substring(0,10)}</span>
                          </Link>
                        </li>
                      )
                    }
                    return ''
                    
                  })
                }
            </ul>
          </div>

        </QueueAnim>
        <QueueAnim
          key="text2"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: 12,
            xs: 12,
          }}
        >
          <div className="title-3 clearfix">
          <Link to={"/Notice"}>
							<div className="left pull-left">
								<a  className="tr">
									展会信息<span className="text-uppercase tr">News</span>
								</a>
							</div>
							<div className="right pull-right">
								<a  className="tr">MORE +</a>
							</div>
              </Link>
						</div>

          <div className="pictrue-4">
            
          <Link className="box_a" to={`/NoticeDetail?id=${info[0]&&info[0].id}`}>
                <div className="imgbox">
                <img className="tr2" src={info[0] && info[0].imgPath?`${BASIC_IMG}${info[0].imgPath}`: noImage} style={{backgroundSize: 'cover', width:'100%'}} />	

                </div>
                <div className="textbox">
                  <div className="biaoti">
                    <h5 className="no-margin overflow tr">{info[0]&&info[0].title}</h5>
                    <span className="text-right">{info[0] ?info[0].createTime.substring(0, 10):''}</span>
                  </div>
                  <p>{info[0]&&info[0].showDesc}</p>
                  <div className="more text-center tr">详情&gt;&gt;</div>
                </div>
             </Link>
            
          </div>
          <div className="text-4">
            <ul className="list-unstyled no-margin clearfix">
            
                {
                  info.map((v, index) => {
                    if(index !=0) {
                      return (
                        <li key={`home_news_${index}`} className="overflow li0">
                            <Link to={`/NoticeDetail?id=${v.id}`}>
                          <a className="tr">{v.title}</a>
                          <span className="text-right">{v.createTime.substring(0,10)}</span>
                          </Link>
                        </li>
                      )
                    }
                    return ''
                    
                  })
                }
              

            </ul>
          </div>

        </QueueAnim>
      </OverPack>
    </div>
  );
}

export default Content1;
