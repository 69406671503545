import React, {useEffect,useState } from 'react';
import {  RightCircleOutlined } from '@ant-design/icons';
import { Card, Row, Col, Pagination, Space, Input, Empty} from 'antd';
import './list.less'
import { Link } from 'react-router-dom';
import {getGoodsList, getqueryTypeList} from '../../../service/api'
import {getResponse, BASIC_IMG} from '../../../common/config'
import {useSearchParams} from 'react-router-dom'

import '../../Home/less/others.less';
const { Meta } = Card;

const List = () => {
    const [total, settotal] = useState(0)
    const [records, setrecords] = useState([])
    const [title, settitle] = useState(title)
    const [pageNo, setpageNo] = useState(1)
    const [pageSize, setpageSize] = useState(8)
    const [pageTotal, setpageTotal] = useState(0)
    const [typeList, settypeList] = useState([
    ])
    const [search, setSearch] = useSearchParams();
    const [typeActive, settypeActive] = useState({})

    useEffect(() => {
        // effect
        getType()
        return () => {
            // cleanup
        }
    }, [])
    const getType = async () => {
      const type = await getqueryTypeList();
        
      if(getResponse(type)){
        const list = type.result.filter(v => v.value.indexOf('product-') !=-1);
        settypeList(list);
        const typeSearch= search.get('type');
        if(typeSearch) {
          getData({type: typeSearch})
          settypeActive(list.filter(v => v.value === typeSearch)[0])
        } else {
          getData({type: list[0].value,})
          settypeActive(list[0])
        }
   
      }
    }
    const  getData = async (params) => {
        // pageNo  pageSize type title
          const param = {
            pageNo: pageNo,
            pageSize: pageSize,
            title: title,
            listFlag:1,
            ...params
          }
          if(!param.title ){
            delete param.title
          }
          const res = await getGoodsList(param);
          if(getResponse(res)) {
            const {listGoodsResultVo: {records, total      }} = res.result;
            settotal(total);
            setrecords(records);
            setpageTotal(parseInt(total/pageSize) + 1)
           
          }
        
    }
    const onChange = (pageNumber) => {
        getData({pageNo: pageNumber})
        setpageNo(pageNumber)
    };
    return (
        <div  className="home-page-wrapper list-wrapper">
          <div className={"home-page list"} style={{display:'flex'}}>
            {/* <div className={"title-wrapper"}>
              <h1 name="title">产品中心</h1>
            </div> */}
            <div className="col-md-3 subnav-list">
              <div className="subnav">
                <ol className="clearfix no-padding no-margin">
                  <b className="pull-left glyphicon glyphicon-th-large hidden-xs hidden-sm"></b>
                  <h1 className="pull-left no-margin"><span className="hidden-md hidden-lg"></span>产品展示</h1>
                  <small className="text-capitalize pull-left hidden-xs hidden-sm">Product</small>
                </ol>
                <ul className="list-unstyled pcdnav">
                  {
                    typeList.map((item, index) => {
                      const onClick = () => {
                        getData({pageNo: 1, type: item.value})
                        setpageNo(1)
                        settypeActive(item)
                      }
                      return <li onClick={onClick} key={`product_type_item_${index}`} className="li0"><a className="text-center"><RightCircleOutlined />
                      {item.text.replace('产品分类-', '')}</a></li>
                      })
                  }

                </ul>
                
              </div>
            </div>
            <div style={{width: '88%'}}>
            <div className="title-4 clearfix" style={{margin: '0px 20px 20px 20px'}}>
									<h5 className="no-margin pull-left">产品展示{typeActive.text? `/${typeActive.text.replace('产品分类-','')}` : ''}</h5>
									<p className="no-margin pull-right"><a className="tr">首页：</a>产品展示</p>
								</div>
              {/* <Space className={'notice_search'} >
                <SearchOutlined  style={{fontSize: 20}}/>
                <Input placeholder="请输入标题" allowClear onPressEnter={(e) => {
                  getData({
                    pageNo: 1,
                    title: e.target.value,
                  })
                  setpageNo(1)
                }} style={{ width: 400, paddingLeft: 30, fontSize: 20}} />
              </Space> */}
              {
                records.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : null
              }

              {/* <OverPack playScale={0.3} style={{width: 'calc(100% - 100px)'}}>
                  
                  <QueueAnim
                  type="bottom"
                  key="block"
                  component={Row}
                  > */}
               <Row>
                  
                  {records.map((item, i) => {
                      
                      return (
                      <Col key={i.toString()} name={i.toString()} className="list-block" md={6} xs={24}>
                          <Link to={`/ProductDetail?id=${item.id}`} >
                          <Card
                              hoverable
                              style={{ width: '198px',height: '310px', marginBottom: '20px', margin: 'auto'}}
                              cover={<img alt="example" style={{height: 200}} src={`${BASIC_IMG}${item.imgPath}`} />}
                              >
                              <Meta title={item.title} description={<div>
                                {item.showDesc}
                              <div>{item.hideDesc}</div>
                              </div>} />
                            </Card>
                            </Link>
                      </Col>
                      );
                  })} 
                  </Row>
                  {/* </QueueAnim> */}
                  <Pagination pageSize={pageSize} current={pageNo}  style={{float:'right'}} total={total} onChange={onChange} />

              {/* </OverPack> */}
            </div>
            
          </div>
        </div>
        
    );
};

export default List;