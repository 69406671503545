import React, { Component } from 'react';
import Nav3 from '../view/Home/Nav3';
import { enquireScreen } from 'enquire-js';
import Footer from '../view/Home/Footer1.jsx';
import FooterLite from '../view/Home/FooterLite.jsx';
import {
  Nav30DataSource,
  Footer10DataSource,
} from '../view/Home/data.source';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
class Layout extends Component {
  constructor(props) {
    super(props);
    console.log("Layout get props", props);
    this.state = {
      isMobile,
      list: [],
      isMainPage: props.mainPage
    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    const {children} = this.props;
    return (
        <div>
          <Nav3
            id="Nav3_0"
            key="Nav3_0"
            dataSource={Nav30DataSource}
            isMobile={this.state.isMobile}
          />
          {children}
          {this.state.isMainPage?
          <Footer list={this.state.list} dataSource={Footer10DataSource} />
          :<FooterLite list={this.state.list} dataSource={Footer10DataSource} /> 
          }
        </div>
    );
  }
}

export default Layout;
