import React, {useEffect,useState } from 'react';
import {  LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Card, Row, Col, Pagination, Space, Input, Empty} from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import './list.less'
import { Link } from 'react-router-dom';
import {getGoodsList, getqueryTypeList} from '../../../service/api'
import {getResponse, BASIC_IMG} from '../../../common/config'

const { Meta } = Card;

const List = () => {
    const [total, settotal] = useState(0)
    const [records, setrecords] = useState([])
    const [title, settitle] = useState(title)
    const [pageNo, setpageNo] = useState(1)
    const [pageSize, setpageSize] = useState(8)
    const [pageTotal, setpageTotal] = useState(0)
    const [typeList, settypeList] = useState([
    ])
    const [typeActive, settypeActive] = useState(1)

    useEffect(() => {
        // effect
        getType()
        return () => {
            // cleanup
        }
    }, [])
    const getType = async () => {
      const type = await getqueryTypeList();
        
      if(getResponse(type)){
        const list = type.result.filter(v => v.value.indexOf('product-') !=-1);
        settypeList(list);

        settypeActive(list[0].value)
        getData({type: list[0].value,})
      }
    } 
    const  getData = async (params) => {
        // pageNo  pageSize type title
      const param = {
        pageNo: pageNo,
        pageSize: pageSize,
        title: title,
        listFlag:1,
        ...params
      }
      if(!param.title ){
        delete param.title
      }
      const res = await getGoodsList(param);
      if(getResponse(res)) {
        const {listGoodsResultVo: {records, total      }} = res.result;
        settotal(total);
        setrecords(records);
        setpageTotal(parseInt(total/pageSize) + 1)
      }

    }
    const onChange = (pageNumber) => {
      if(pageNumber === 'left' && pageNo !=1){
        getData({pageNo: pageNo - 1, type: typeActive})
        setpageNo(pageNo - 1)
      }
      if(pageNumber === 'right' && pageNo != pageTotal){
        getData({pageNo: pageNo + 1, type: typeActive})
        setpageNo(pageNo + 1)
      }
      if(pageNo === pageTotal) {
        getData({pageNo: 1, type: typeActive})
        setpageNo(1)
      }
        // getData({pageNo: pageNumber})
        // setpageNo(pageNumber)
    };
    return (
        <div  className="home-page-wrapper list-wrapper">
          <div className={"home-page list"}>
            {/* <div className={"title-wrapper"}>
              <h1 name="title">产品中心</h1>
            </div> */}
            <div className="title-1 text-center">
              <Link to={'/Product'}>
								<small className="text-uppercase">Products</small>
								<h5 className="no-margin"><span>产品展示</span></h5>
              </Link>
						</div>
            <div className="title-2 text-center">
							<ul className="list-unstyled no-margin clearfix">
								{
                  typeList.map((item, index) => {
                  const onClick = () => {
                    
                    getData({pageNo: 1, type: item.value})
                    setpageNo(1)
                    settypeActive(item.value)
                  }
                  return 	<li onClick={onClick} key={`type_list_item${index}`} className={`${item.value === typeActive ? 'active' : ''} li0`}>
                    <a className="tr">{item.text.replace('产品分类-','')}</a></li>
                  })
                }
							</ul>
						</div>
            {/* <Space className={'notice_search'} >
              <SearchOutlined  style={{fontSize: 20}}/>
              <Input placeholder="请输入标题" allowClear onPressEnter={(e) => {
                getData({
                  pageNo: 1,
                  title: e.target.value,
                })
                setpageNo(1)
              }} style={{ width: 400, paddingLeft: 30, fontSize: 20}} />
            </Space> */}
            {
              records.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : null
            }
            <div className={'list-page'}>
              <div className={'list-page-changeItem'}>
              {
                total > records.length ? <LeftOutlined onClick={() => {onChange('left')}} style={{fontSize: 48, cursor:'pointer'}}/> : null
              }
              
              </div>
              <OverPack playScale={0.3} style={{width: 'calc(100% - 100px)'}}>
                  
                  <QueueAnim
                  type="bottom"
                  key="block"
                  leaveReverse
                  component={Row}
                  >
                  
                  {(records.length < 9 ? records : records.splice(0,8)).map((item, i) => {
                  // {records.map((item, i) => {
                      
                      return (
                      <Col key={i.toString()} name={i.toString()} className="list-block" md={6} xs={24}>
                          <Link to={`/ProductDetail?id=${item.id}`} >
                          <Card
                              hoverable
                              style={{ width: '198px',height: '310px', marginBottom: '20px', margin: 'auto'}}
                              cover={<img alt="example" style={{height: 200}} src={`${BASIC_IMG}${item.imgPath}`} />}
                              >
                              {/* <Meta title={item.title}/> */}

                              <Meta title={item.title} description={<div>
                                {item.showDesc}
                              <div>{item.hideDesc}</div>
                              </div>} />
                            </Card>
                            </Link>
                      </Col>
                      );
                  })} 
                  </QueueAnim>
                  {/* <Pagination pageSize={pageSize} current={pageNo}  style={{float:'right'}} total={total} onChange={onChange} /> */}

              </OverPack>
              <div className={'list-page-changeItem'}>
              {
                total > records.length ?   <RightOutlined onClick={() => {onChange('right')}} style={{fontSize: 48,  cursor:'pointer'}} /> : null
              }
            
              </div>
            </div>
            
            
          </div>
        </div>
        
    );
};

export default List;