
import ApiRequest, { IResponseResult } from '../common/request';

/**
 * @todo 获取验证码
 * :{mobile,smsmode}
 */
export const getCodeApi = async (params) => {
  return ApiRequest.post(`/sys/sms`, params);
};
 /**
 * @todo 注册
 * 字段:{mobile,smscode,username,password,email}
 */
export const registerApi = async (params) => {
  return ApiRequest.post(`/sys/user/register`, params);
};
 /**
 * @todo 登录
 *   //username,password,loginSource
 */
export const loginApi = async (params) => {
  return ApiRequest.post(`/sys/login`, params);
};

 /**
 * @todo 
 *   //type  bottom,下方参数
top,上方参数
 */
export const getTopBottom = async (params) => {
  return ApiRequest.get(`/biz/api/getCommonTopElements?`, params);
};

 /**
 * @todo 
 *   //产品列表  pageNo  pageSize type title
 * product:产品 , announcement:公告，introduce:简介,clock:挂钟定制,onlineShip:线上商城
 */
export const getGoodsList = async (params) => {
  return ApiRequest.get(`/biz/api/queryGoodList?`, params);
};
 /**
 * @todo 
 *   // 详情页面
get请求
参数  id
 */
export const getDetail = async (params) => {
  return ApiRequest.get(`/biz/api/queryDetail?`, params);
};

 /**
 * @todo 
 *   // 分类
get请求
参数  id
 */
export const getqueryTypeList = async (params) => {
  return ApiRequest.get(`/biz/api/queryTypeList?`, params);
};









  
