import React, { useEffect, useState } from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getResponse, BASIC_IMG } from '../../common/config';
import { getGoodsList } from '../../service/api';
import { Link } from 'react-router-dom';

function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  const [records, setrecords] = useState({})
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  useEffect(() => {
    getData();
    return () => {
    }
  }, [])
  const getData= async() => {
    const param = {
      pageNo: 1,
      pageSize: 10,
      type: 'introduce',
    }
    const res = await getGoodsList(param);
    if(getResponse(res)) {
      const {contentResultVo} = res.result;
      setrecords(contentResultVo)
    }
  }
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      
      <OverPack {...dataSource.OverPack} component={Row}>
      <div className="ant-col-md-24">
						<div className="title-1 text-center">
            <Link to={`/Business`}>
								<small className="text-uppercase">Company Profile</small>
								<h5 className="no-margin"><span>公司简介</span></h5>
              </Link>
						</div>
					</div>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <span {...dataSource.img}>
            <img src={`${BASIC_IMG}${records.imgPath}`} width="100%" alt="img" />
          </span>
        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          <div key="p" {...dataSource.content} style={{height:'260px', overflow: 'hidden'}}>
            {records.showContent}
          </div>
          <Link to={`/Business`}>
          <div style={{textAlign:'right', color: '#b18d5b'}}>
            {'详情>>>'}
          </div>
          </Link>

        </QueueAnim>
      </OverPack>
    </div>
  );
}

export default Content1;
