import { BASE_URL, PRE_URL } from './config';
import {notification} from 'antd';
/**
 * 检测接口结果是否成功
 */
export const checkResponseSuccess = ({ response, type }) => {
  if (type === 1) {
    return response.code === 0;
  }
  return response.success;
};

/**
 * queryString 与 object 互转
 */
export class qs {
  static stringfy(obj) {
    const params = new URLSearchParams(obj);
    return params.toString();
  }

  static parse(str) {
    const params = new URLSearchParams(str);
    const newParams= {};
    Array.from(params.entries()).forEach(([key, value]) => {
      newParams[key] = value;
    });
    return newParams;
  }
}

/**
 * 将body数据转换为urlencode类型
 */
export const formatJsonToFormData = (data, formData) => {
  const newFormData = formData ? formData : new FormData();
  Object.entries(data).forEach(([key, value]) => {
    newFormData.append(key, value);
  });
  return newFormData;
};

class ApiRequest {
  baseOptions(params, method = 'GET'){
    let { url, data } = params;
    // let contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
    let contentType = 'application/json;charset=UTF-8';
    contentType = params.contentType || contentType;
    const token = localStorage.getItem('token');
    const hastoken = ['/invoice/getCode', '/invoice/validate'].find((v) => v === url);
    const option= {
      headers: {
        'Content-Type': contentType,
        'X-Access-Token': hastoken ? '' : token || ''

      },
      method: method,
      body: data,
      credentials: 'include',
    };
    return fetch(BASE_URL + url, option)
      .then((response) => {
        if (response.status === 401) {
          // notification.error({
          //   description: '登录过期，请重新登录'
          // })
          window.location.href=`${PRE_URL}/#/login`
        }
        if (response.status >= 200 && response.status < 300) {
          
          return response;
        }
        // const history = useHistory();
        // history.push('/404')
        // window.location.href = '/#/404';
      })
      .then((res) => {
        if(res) {
          return res.json();
        }
      })
      .catch((error) => {
        // const history = useHistory();
        // history.push('/404')
        window.location.href = `${PRE_URL}/#/404`;
        // throw new Error(error);
      });
  }

  get(url, data = {}) {
    let option = { url: `${url}${qs.stringfy(data)}` };
    return this.baseOptions(option);
  }

  post(url, data, contentType) {
    let params = {
      url,
      data: JSON.stringify(data),
      contentType,
    };
    return this.baseOptions(params, 'POST');
  }

  put(url, data = '') {
    let option = { url, data };
    return this.baseOptions(option, 'PUT');
  }

  delete(url, data = '') {
    let option = { url, data };
    return this.baseOptions(option, 'DELETE');
  }
}

export default new ApiRequest();
