import {  RightCircleOutlined } from '@ant-design/icons';
import {  List } from 'antd';
import React, {useState, useEffect} from 'react';
import Layout from '../../Layout';
import './index.less';
import {getGoodsList, getqueryTypeList} from '../../service/api'
import {getResponse, BASIC_IMG} from '../../common/config'
import '../Home/less/others.less'
import { Link, useSearchParams } from 'react-router-dom';
import Banner1 from '../Home/Banner1'
import {
  Banner10DataSource,

} from '../Home/data.source';
import noImage from '../../assets/noImage.jpeg'
const data = Array.from({ length: 23 }).map((_, i) => ({
  href: 'https://ant.design',
  title: `ant design part ${i}`,
  // avatar: `https://joesch.moe/api/v1/random?key=${i}`,
  description:
    'Ant Design, a design language for background applications, is refined by Ant UED Team.',
  content:
    'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
}));


const Content = () => {
  const [total, settotal] = useState(0)
  const [records, setrecords] = useState([])
  const [title, settitle] = useState(title)
  const [pageNo, setpageNo] = useState(1)
  const [pageSize, setpageSize] = useState(10)
  const [typeList, settypeList] = useState([])
  const [typeActive, settypeActive] = useState({})
  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    getType()
    return () => {
      
    }
  }, [])
  const getType = async () => {
    const type = await getqueryTypeList();
      
    if(getResponse(type)){
      const list = type.result.filter(v => v.value.indexOf('announcement-') !=-1);
      settypeList(list);
      const typeSearch= search.get('type');
      if(typeSearch) {
        getData({type: typeSearch})
        settypeActive(list.filter(v => v.value === typeSearch)[0])
      } else {
        getData({type: list[0].value,})
        settypeActive(list[0])
      }
    }
  }
  const  getData = async (params) => {
    // pageNo  pageSize type title
    const param = {
      pageNo: pageNo,
      pageSize: pageSize,
      title: title,
      type: 'announcement-news',
      listFlag: 1,
      ...params,
      
    }
    if(!param.title){
      delete param.title
    }
    const res = await getGoodsList(param);
    if(getResponse(res)) {
      const {listGoodsResultVo: {records, total      }} = res.result;
      settotal(total);
      setrecords(records)
    }
  }
  return (
    <Layout>
      <Banner1
        id="Banner1_0"
        key="Banner1_0"
        dataSource={Banner10DataSource}
      />
      <div className='notice_wrap home-page-wrapper content0-wrapper' style={{display: 'flex'}}>
        <div className="col-md-3 subnav-list">
              <div className="subnav">
                <ol className="clearfix no-padding no-margin">
                  <b className="pull-left glyphicon glyphicon-th-large hidden-xs hidden-sm"></b>
                  <h1 className="pull-left no-margin"><span className="hidden-md hidden-lg"></span>企业公告</h1>
                  <small className="text-capitalize pull-left hidden-xs hidden-sm">Business</small>
                </ol>
                <ul className="list-unstyled pcdnav">
                  {
                    typeList.map((item, index) => {
                      const onClick = () => {
                        getData({pageNo: 1, type: item.value})
                        setpageNo(1)
                        settypeActive(item)
                      }
                      return <li onClick={onClick} key={`product_type_item_${index}`} className="li0">
                        <a className="text-center"><RightCircleOutlined />
                      {item.text.replace('公告分类-','')}</a></li>
                      })
                  }

                </ul>
                
              </div>
            </div>
        <div style={{width: '88%'}}>
       {/* <Space className={'notice_search'} >
        <SearchOutlined  style={{fontSize: 20}}/>
        <Input placeholder="请输入标题" allowClear onPressEnter={(e) => {
          getData({
            pageNo: 1,
            title: e.target.value,
          })
          setpageNo(1)
        }} style={{ width: 400, paddingLeft: 30, fontSize: 20}} />
       </Space> */}

      {/* <OverPack playScale={0.3}> */}
      <div className="title-4 clearfix" style={{margin:'0 20px 20px 20px'}}>
									<h5 className="no-margin pull-left">企业公告{typeActive.text? `/${typeActive.text.replace('公告分类-','')}` : ''}</h5>
									<p className="no-margin pull-right"><a className="tr">首页：</a>企业公告</p>
								</div>
      <List
        itemLayout="vertical"
        size="large"
        className="notice_wrap_list pictrue-7-list"
        pagination={{
          onChange: (page) => {
            getData({pageNo: page})
            setpageNo(page)
          },
          current: pageNo,
          total: total,
          pageSize: pageSize,
        }}
        dataSource={records}
        renderItem={(item, index) =>{
          const randNum =  Math.floor(Math.random() * 10) + 1
          return <div key={`notic_item_${index}`} className="box_notic">
            <Link className="box_a" to={`/NoticeDetail?id=${item.id}`}>
            <div className="imgbox tr">
            <img className="tr2" src={item.imgPath ? `${BASIC_IMG}${item.imgPath}` : noImage} style={{backgroundSize: 'cover', width: '100%'}} />	
                </div>
            <div className="textbox">
              <h5 className="overflow tr">{item.title}</h5>
              <span>{item.createTime}</span>
              <div className="notice_item_content_desc">{item.showDesc}
              {item.hideDesc}</div>
              <div className="more text-right"><span className="text-center tr">查看详情</span></div>
            </div>
          </Link>
        </div>
         }}
      />
      {/* </OverPack> */}
      </div>
      </div>
    </Layout>
  )
};

export default Content;
