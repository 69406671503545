import React, { useEffect, useState} from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Spin, Modal } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from '../Home/utils';
import ImgCode from  '../../component/ImgCode';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, message, Form, Input } from 'antd';
import './index.less'
import {loginApi, getTopBottom} from '../../service/api'
import {getResponse, BASIC_IMG, PRE_URL} from '../../common/config'
import MD5 from 'MD5';
import { Link } from 'react-router-dom';

const Content =({dataSource, isMobile, ...props})  =>{
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [isVilid, setIsVilid] = useState(false)
  useEffect(() => {
    getData()
    return () => {
      
    }
  }, [])
  const  getData =  async() =>  {
    const res = await getTopBottom({type: 'title'});
    if(getResponse(res)) {
      setTitle(res.result[0])
    }
  }
  const onFinish = async(values) => {
    setLoading(true)
    const res = await loginApi({...values, loginSource: 'APP'});
    if(getResponse(res)) {
      message.success('登录成功！');
      localStorage.setItem('token', res.result.token);
      localStorage.setItem('userInfo', JSON.stringify(res.result.userInfo));
      window.location.href= `${PRE_URL}/#/` 
    }
    setLoading(false)
  };
  return (
    <Spin spinning={loading}>
    <div className={'login_wrap'}>
      <div className="login_title">
      <span className="login-logo"><img  src={`${BASIC_IMG}${title.imgPath}`} /></span>
        <h1>{title.title}</h1>
      </div>
      <Form
    name="normal_login"
    className="login-form"
    form={form}
    initialValues={{ remember: true }}
    onFinish={(e) => {
      if(!isVilid){
        setShowModal(true)
      } else {
        onFinish(e)
      }
    }}
  >
    <Form.Item
      name="username"
      rules={[{ required: true, message: '请输入手机号!' }]}
    >
      <Input style={{fontSize: 18}} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入手机号" />
    </Form.Item>
    <Form.Item
      name="password"
      rules={[{ required: true, message: '请输入密码!' }]}
    >
      <Input
        prefix={<LockOutlined className="site-form-item-icon" />}
        type="password"
        style={{fontSize: 18}}
        placeholder="请输入密码"
      />
    </Form.Item>
    {/* <Form.Item>
      <Form.Item name="remember" valuePropName="checked" noStyle>
        <Checkbox>记住我</Checkbox>
      </Form.Item>

      <a className="login-form-forgot" href="">
        忘记密码
      </a>
    </Form.Item> */}

    <Form.Item>
      <Button style={{marginBottom: '10px'}} type="primary" htmlType="submit" className="login-form-button">
        登录
      </Button>
      <Link to="/Register">
      <a 
      //  href={`${PRE_URL}/#/Register`}
      >还没有账号？点击注册!</a></Link>
    </Form.Item>
  </Form>
    </div>
    <Modal
      // open={open}
      title="图形验证"
      // onOk={handleOk}
      onCancel={() => {
        setShowModal(false)
      }}
      visible={showModal}
      footer={[]}
    >
     <ImgCode
        onMatch={() => {
          setTimeout(() => {
            setShowModal(false)
            setIsVilid(true)
            onFinish(form.getFieldsValue())
          }, 1000)
        }}
      /> 
    </Modal>
    </Spin>
  );
}

export default Content;
