import React, {useEffect, useState} from 'react';
import {  Card } from 'antd';
import Layout from '../../Layout';
import {useSearchParams} from 'react-router-dom'
import { getDetail, getqueryTypeList} from '../../service/api'
import {getResponse} from '../../common/config'
import {  RightCircleOutlined } from '@ant-design/icons';

import '../Home/less/others.less'
import Banner1 from '../Home/Banner1'
import {
  Banner10DataSource,

} from '../Home/data.source';
import { Link } from 'react-router-dom';
const { Meta } = Card;
const Content = () => {
  const [search, setSearch] = useSearchParams();
  const [detail, setDetail] = useState('')
  const [typeList, settypeList] = useState([
  ])

  useEffect(() => {
    getData();
    getType()
    return () => {
    }
  }, [])
  const getType = async () => {
    const type = await getqueryTypeList();
      
    if(getResponse(type)){
      const list = type.result.filter(v => v.value.indexOf('product-') !=-1);
      settypeList(list);
    }
  }
  const getData =  async () =>  {
    // pageNo  pageSize type title
  

    const param = {
      id: search.get('id')
    }
    const res = await getDetail(param);
    if(getResponse(res)) {
      setDetail(res.result)
    }
  }

  return (
    <Layout>
        <Banner1
            id="Banner1_0"
            key="Banner1_0"
            dataSource={Banner10DataSource}
          />
       <div className="home-page-wrapper content0-wrapper">
        <div className="home-page content0" style={{display:'flex'}}>
        <div className="col-md-3 subnav-list">
              <div className="subnav">
                <ol className="clearfix no-padding no-margin">
                  <b className="pull-left glyphicon glyphicon-th-large hidden-xs hidden-sm"></b>
                  <h1 className="pull-left no-margin"><span className="hidden-md hidden-lg"></span>产品展示</h1>
                  <small className="text-capitalize pull-left hidden-xs hidden-sm">Product</small>
                </ol>
                <ul className="list-unstyled pcdnav">
                  {
                    typeList.map((item, index) => {
                      const onClick = () => {

                      }
                      return <li onClick={onClick} key={`product_type_item_${index}`} className="li0">
                            <Link to={`/Product?type=`+item.value} >
                        <a className="text-center" ><RightCircleOutlined />
                      {item.text.replace('产品分类-', '')}</a>
                      </Link>
                      </li>
                      })
                  }

                </ul>
                
              </div>
            </div>
            <div style={{width: '88%'}}>
            <div className="title-4 clearfix" style={{margin: '0px 20px 20px 20px'}}>
									<h5 className="no-margin pull-left">产品展示</h5>
									<p className="no-margin pull-right"><a  className="tr">首页：</a>产品展示</p>
								</div>
                <div class="title-5 text-center">
              <h5>{detail.title}</h5>
									<p>发布时间：{detail.createTime}</p>
								</div>
              <div style={{padding: '20px'}} dangerouslySetInnerHTML={{__html:detail.content}}></div>
          </div> 
          </div>
      </div>
    </Layout>
  );
}

export default Content;
