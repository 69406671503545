/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Nav3 from './Nav3';
import Banner1 from './Banner1';
import Content1 from './Content1';
import Content12 from './Content12';
import Content3 from './Content3';
import Feature5 from './Feature5';
import ImgItem from './ImgItem';
import Product from '../Product/component/list';
import Layout from '../../Layout';

import {
  Nav30DataSource,
  Banner10DataSource,
  Content10DataSource,
  Content50DataSource,
  Content30DataSource,
  Feature50DataSource,
  Footer10DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      list: [],
      isMainPage: true
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
   
    /* 如果不是 dva 2.0 请删除 end */
    // 
  }


  render() {

    console.log("render home")
    const children = [
      <Banner1
        id="Banner1_0"
        key="Banner1_0"
        dataSource={Banner10DataSource}
      />,
      
      <Product  key="home2"/>,
      <ImgItem       id="Banner12_0"
      key="Banner2_0"
      />,
      <Content1
        id="Content1_0"
        key="Content1_0"
        dataSource={Content10DataSource}

      />,
      <Content12
        id="Content12_0"
        key="Content12_0"
        dataSource={Content10DataSource}
      />,
      // <Content3
      //   id="Content3_0"
      //   key="Content3_0"
      //   dataSource={Content30DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <Feature5
      //   id="Feature5_0"
      //   key="Feature5_0"
      //   dataSource={Feature50DataSource}
      //   isMobile={this.state.isMobile}
      // />,
    ];

    return (
      <Layout mainPage={true}>
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
      </Layout>
    );
  }
}
