
import React, {useState, useEffect} from 'react';
import Layout from '../../Layout';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import {getGoodsList} from '../../service/api'
import {getResponse} from '../../common/config'
import QueueAnim from 'rc-queue-anim';


const Content = (props) => {
  const [records, setrecords] = useState([])


  useEffect(() => {
    getData({})
    return () => {
      
    }
  }, [])
  const  getData = async (params) => {
    // pageNo  pageSize type title
    const param = {
      pageNo: 1,
      pageSize: 10,
      type: 'introduce',
      ...params,
    }
    const res = await getGoodsList(param);
    if(getResponse(res)) {
      const {contentResultVo: {content    }} = res.result;
      setrecords(content)
    }
  }
  
  console.log("rander Business")
  return (
    
    <Layout>
     <div className="home-page-wrapper content0-wrapper">
        <div className="home-page content0">
          <div className="title-wrapper">
            <h1 name="title">公司简介</h1>
          </div>
          <OverPack playScale={0.3}>
            <QueueAnim key="u" type="bottom">
              <div dangerouslySetInnerHTML={{__html:records}}></div>
            </QueueAnim>
          </OverPack>          
          </div>
      </div>
    </Layout>
  )
};

export default Content;
